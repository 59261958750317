/* App.css */
body {
  font-family: 'Amazon Ember', sans-serif; /* Specify the desired font family */
  
  /* Use the amber color */
}
body {
  font-family: 'Amazon Ember', sans-serif; /* Use the Amber font family */
  
  background-color: #f0f0f0; /* Amazon-like background color */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.container {
 
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
    max-width: 10000px;
    padding: 20px;
    border: 1px solid #ddd;
    
    background-color: #f9f9f9;
    font-family: 'Amazon Ember', sans-serif; 
     /* Horizontally center-align */
    align-items: left;
    
    background-color: #fff; /* White background similar to Amazon's design */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 4px; /* Slight border radius */
  /* Padding inside the container */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
    
  /* Vertically center-align */
   
  }

  .container1 {
    margin-top: 6px;
    
    max-width: 10000px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    font-family: 'Amazon Ember', sans-serif; 
     /* Horizontally center-align */
    align-items: center;
    
    background-color: #fff; /* White background similar to Amazon's design */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 4px; /* Slight border radius */
  /* Padding inside the container */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
    
  /* Vertically center-align */
   
  }

  .footercontainer {
 

    margin-right: 15px;
    max-width: 10000px;
    padding: 10px;
    border: 1px solid #ddd;
    
    background-color: #f9f9f9;
    font-family: 'Amazon Ember', sans-serif; 
     /* Horizontally center-align */
    align-items: left;
    
    background-color: #fff; /* White background similar to Amazon's design */
    border: 1px solid #ddd; /* Light gray border */
    border-radius: 4px; /* Slight border radius */
  /* Padding inside the container */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
    
  /* Vertically center-align */
   
  }

  h1 {
    font-family: 'Amazon Ember', sans-serif; 
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .heading {
   /* Change the color to your preferred value */
    font-size: 24px; /* Adjust the font size */
   
    
    font-family: 'Amazon Ember', sans-serif; 
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 5px;/* Adjust the bottom margin */
}
.heading2 {
  /* Change the color to your preferred value */
   font-size: 18px; /* Adjust the font size */
  
   
   font-family: 'Amazon Ember', sans-serif; 
   text-align: left;
   margin-bottom: 5px;
   
   margin-left: 10px;
   padding: 5px;/* Adjust the bottom margin */
}

  h3 {
    font-family: 'Amazon Ember', sans-serif; 
    text-align: left;
    margin-bottom: 15px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    font-family: 'Amazon Ember', sans-serif; 
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-control {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
  }
  
  .select-control {
    height: 34px;
  }
  
  .submit-button {
    margin-top: 20px;
  }
  
  .alert-message {
    margin-top: 20px;
  }
  
  /* Optional: Apply a lighter background to the selected option */
  option:checked {
    background-color: #f0f0f0;
  }
  
  /* Optional: Apply styles to the submit button on hover */
  .submit-button:hover {
    background-color: #007bff;
    color: #fff;
  }

  /* styles.css */

/* Style for the file input container */
.file-input-container {
  display: flex;
  align-items: center; /* Center items vertically */
  width: 50%;
}

/* Style for the file input */
.file-input {
  border: 1px solid #ccc;
  padding: 8px;
  width: 100%;
  flex-grow: 1; /* Expand to fill available space */
}



/* Style for the "Upload File" button */
.primary-button {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-left: 10px; /* Add some space between the input and the button */
  cursor: pointer;
}

.primary-button:hover {
  background-color: #0056b3;
}

/* Rest of your existing styles... */

  /* styles.css */

.file-error {
  color: red;
  margin-top: 5px;
}

/****************************/





.progress-text {
  font-family: 'Amazon Ember', sans-serif; 

  color:  #0056b3;
  text-align: left;
  /* Apply the blinking animation */
}

@keyframes blink {
  0% {
      opacity: 1; /* Fully visible */
  }
  50% {
      opacity: 0; /* Invisible */
  }
  100% {
      opacity: 1; /* Fully visible */
  }
}


/****************************/



/* Style for the file input container */
.file-input-container {
  display: flex;
  align-items: center;
}

/* Style for the file input */
.file-input {
  opacity: 0; /* Hide the input */
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Style for the label */
.custom-file-label {
  background-color: #f90;
  color:#333;
  padding: 10px 20px;
  border: 2px solid #f90;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
  white-space: nowrap; /* Prevent label text from wrapping */
}

/* Hover style for the label */
.custom-file-label:hover {
  background-color: white;
  color: #333;
}


/************************************/



/* Style for the download link as a button */
.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: #fff; /* Button text color */
  text-decoration: none; /* Remove underlines from the link */
  border: none;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

/* Hover style for the download link button */
.download-link:hover {
  background-color: #0056b3; /* Darker background color on hover */
}



/***********************************************/

/* Style for the product description */
.product-description {
  font-family: 'Amazon Ember', sans-serif; /* Specify the desired font family */
  
  font-size: 16px;
  line-height: 1.5; /* Adjust line height for readability */
  margin-bottom: 20px; /* Add spacing below the description */
   /* Text color */
  text-align: left;
   /* Center-align the text */
}
/* App.css */
/* Amazon-style file input */
.amazon-file-input {
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding: 10px; /* Add padding to the container */
  background-color: #232f3e; /* Background color for the container */
  border: 1px solid #232f3e; /* Border for the container */
  border-radius: 5px; /* Rounded corners for the container */
  cursor: pointer;
  color:white
}

.amazon-file-input input[type="file"] {
  font-family: 'Amazon Ember', sans-serif; 
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

/* Style the text inside the container */
.amazon-file-input span {
  font-size: 13px; /* Adjust the font size as needed */
  line-height: 10px; /* Vertically center the text */
  display: block;
}

/* Add a hover effect for the container */
.amazon-file-input:hover {
  background-color:white;
  color: black;
  font-family: 'Amazon Ember', sans-serif; 
   /* Change the background color on hover */
}


/* App.css */
/* Amazon Styled Button */
/* src/App.css */

/* Amazon Download Button Styles */
.amazon-download-button {
  display: inline-block;
  padding: 8px 8px;
  background-color: #FF9900; /* Amazon-like button color */
  color: #fff; /* Amazon-like text color */
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  display: block;
  transition: background-color 0.3s ease-in-out;
  font-family: 'Amazon Ember', sans-serif; 
  font-size: 13px;

  
  
}

/* Define the styling for the download button
.amazon-download-button {
  display: inline-block;
  padding: 6.5px 8px;
  background-color: #FF9900; /* Amazon's orange color, you can change it to match your preference 
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  
}

/* Style the button on hover 
.amazon-download-button:hover {
  background-color: #FF8C00; /* Slightly darker orange for hover effect 
}

.amazon-download-button:hover {
  background-color: #E68500; /* Amazon-like button color on hover 
}
*/

/* src/App.css */

/* Success Message Styles */
.success-message {
  background-color: #dff0d8; /* Green background color similar to Amazon's success messages */
  color: #3c763d; /* Dark green text color */
  border: 1px solid #d6e9c6; /* Light green border */
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 4px;
}

.amber-icon {
  color: amber;
}

/* Amazon-style file input */
.amazon-file-input1 {
  position: relative;
  display: inline-block;
  padding: 9.5px 9px;
  background-color: #232f3e; /* Background color for the container */
  border: 1px solid #232f3e; /* Border for the container */
  border-radius: 5px; /* Rounded corners for the container */
  cursor: pointer;
  color:white;
  
  display: inline-block;
 
  background-color: #FF9900; /* Amazon's orange color, you can change it to match your preference */
  color: white;
  text-decoration: none;
  border: none;
  



}



/* Style the text inside the container */
.amazon-file-input1 span {
  font-size: 13px; /* Adjust the font size as needed */
  line-height: 13px; /* Vertically center the text */
  display: block;
}

/* Add a hover effect for the container */
.amazon-file-input1:hover {
  background-color: #E68500; /* Amazon-like button color on hover */
   /* Change the background color on hover */
}


.left-aligned-container {
  margin-left: 15px;
  margin-bottom: 50px;
  margin-right: 15px;
  max-width: 10000px;
  padding: 20px;
  border: 1px solid #ddd;
  
  background-color: #f9f9f9;
  
   /* Horizontally center-align */
  align-items: left;
  
  background-color: #fff; /* White background similar to Amazon's design */
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 4px; /* Slight border radius */
/* Padding inside the container */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
  overflow-y: auto;
  max-height: 400px;
 
  
}

.left-aligned-container h1,
.left-aligned-container p,
.left-aligned-container span,
.left-aligned-container .version h2,
.left-aligned-container .version h3 {
  color: #333;
}

.left-aligned-container p {
  margin-bottom: 5px;
}

.left-aligned-container span {
  font-weight: bold;
}

.left-aligned-container .version {

  margin-top: 5px;
}

/* Style for h2 within .left-aligned-container */
.left-aligned-container h2 {
  color:  #333; /* Change the color to your preferred value */
  /* Adjust the font size */
  margin-bottom: 15px; /* Adjust the bottom margin */
}




/* Styling for a modern primary button */
button.btn-primary {
  background-color: #ff9900; /* Orange color */
  border: 1px solid #ff9900;
  color: #ffffff; /* White text */
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  border-radius: 4px; /* Rounded corners */
}

/* Styling for the button on hover */
button.btn-primary:hover {
  background-color: #e68a00; /* Darker shade of orange on hover */
  border-color: #e68a00;
}

/* Styling for the button when disabled */
button.btn-primary:disabled {
  background-color: #cccccc; /* Light gray for disabled state */
  border-color: #cccccc;
  cursor: not-allowed;
  color: #999999; /* Gray text for disabled state */
}
.form-field {
  margin-bottom: 1em; /* Adjust the spacing as needed */
}


/* styles.css */

.success-message {
  color: green;
}

.fail-message {
  color: red;
}


.version-wrapper {
  margin-bottom: 10px; /* Adjust as needed */
}



/* Using a more specific selector */
span#version-number a.phone-link {
  color: #333!important; /* Your custom link color */
  cursor: pointer;
  text-decoration: underline;
}

/* Using !important */
a.phone-link {
  color: #333 !important; /* Your custom link color */
  cursor: pointer !important;
  text-decoration: underline !important;
}





/* styles.css */

.card {
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
}

.input-field {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.table {
  margin-bottom: 1rem;
  border-collapse: collapse;
  width: 80%;
}

.table th, .table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.upload-btn {
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.upload-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.upload-btn:hover {
  background-color: #0056b3;
}

.uploaded-files {
  margin-top: 1rem;
}

.uploaded-files li {
  margin-bottom: 0.5rem;
}



/* styles.css */

.table {
  width: 80%;
  border: 1px solid #ddd; /* Add border to the table */
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd; /* Add border to the right of each cell */
}

.table th:last-child,
.table td:last-child {
  border-right: none; /* Remove right border for the last column */
}


.expandable-button {
  background-color: #0073e6; /* Amazon-like color */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  line-height: 13px;
  font-size: 13px;
}

.expandable-button:hover {
  background-color: #005bb5; /* Darker shade on hover */
}

.readme-content {
  background-color: #f9f9f9; /* Light background */
  padding: 15px;
  border: 1px solid #ddd; /* Light border */
  border-radius: 5px;
  margin-top: 10px;
}

.readme-content h3 {
  margin-top: 0;
}
